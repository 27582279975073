/**
 * Salta
 *
 * @author Robuust
 * @author Bob Olde Hampsink <bob@robuust.digital>
 */

import { Controller } from '@hotwired/stimulus';

/**
 * Form Controller.
 */
export default class extends Controller {
  static targets = ['form', 'fields', 'send', 'success', 'submit', 'error'];

  /**
   * Show form by subject.
   *
   * @param {Event} e
   */
  async subject(e) {
    const { id } = e.target;
    const response = await fetch(`form-${id}`);
    this.fieldsTarget.innerHTML = await response.text();
    this.fieldsTarget.classList.remove('hidden');
    this.sendTarget.classList.remove('hidden');
  }

  /**
   * Submit the form
   */
  async submit(e) {
    e.preventDefault();
    this.submitTarget.disabled = true;
    if (this.hasErrorTarget) {
      this.errorTarget.classList.add('hidden');
    }
    const response = await fetch(window.location.href, {
      method: 'POST',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
      },
      body: new FormData(this.element),
    });
    const result = await response.json();

    if (response.ok && ('success' in result || 'submission' in result)) {
      this.formTarget.classList.add('hidden');
      this.successTarget.classList.remove('hidden');
    } else {
      this.submitTarget.disabled = false;
      if (this.hasErrorTarget) {
        const errors = result.errors ? result.errors : [[result.message]];
        this.errorTarget.innerHTML = `
          <div class="bg-red-200 p-2 mb-6 inline-block">
            <span>Er is iets misgegaan bij het verzenden:</span>
            <ul>
              ${Object.entries(errors).map((error) => `<li>${error[1][0]}</li>`)}
            </ul>
          </div>
        `;
        this.formTarget.classList.add('hidden');
        this.errorTarget.classList.remove('hidden');
      }
    }
  }

  /**
   * Disables form on submit.
   *
   * @param {Event} e
   */
  disableForm(e) {
    this.formTarget.disabled = true;
    e.submitter.disabled = true;
  }
}
